<template>
  <section
    class="timeline-block"
    :class="{
      'primary-type': type == 'primary',
      'white-type': type == 'white',
    }"
  >
    <div class="timeline-block__body">
      <div class="timeline-block__title">{{ title }}</div>
      <div class="timeline-block__list">
        <div
          class="timeline-block__item"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="timeline-block__item-index">
            <span>{{ index + 1 }}</span>
          </div>
          <div class="timeline-block__item-info">
            <!-- <div class="timeline-block__item-title">{{ item.title }}</div> -->
            <div class="timeline-block__item-title" v-html="item.title"></div>
            <div class="timeline-block__item-text" v-html="item.text"></div>
          </div>
          <div class="timeline-block__item-image">
            <img :src="require('@/assets/' + item.img)" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      value: String,
      default: null,
    },
    items: {
      value: Array,
      default: () => [],
    },
    type: {
      value: String,
      default: "primary",
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-block {
  width: 100%;
  max-width: 1400px;
  align-self: center;
  padding: 120px 0;

  @media screen and (max-width: 940px) {
    padding: 60px 0;
  }

  // &.primary-type {
  //   background: $white;
  // }

  &.primary-type &__title {
    color: $primary-color;
  }

  &.primary-type &__list::before {
    background: $primary-color;
  }

  &.primary-type &__item {
    &-index {
      color: $white;
      background: $primary-color;
    }

    &-title {
      color: $primary-color;
    }

    &-text {
      color: $primary-color;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgb(62, 104, 138, 0.3);
    }

    &:last-child {
      .timeline-block__item-index {
        span {
          &::before {
            background: rgb(239,245,249);
          }
        }
      }
    }
  }

  &.white-type {
    background: $primary-color;
  }

  &.white-type &__title {
    color: $white;
  }

  &.white-type &__list::before {
    background: $white;
  }

  &.white-type &__item {
    &-index {
      color: $primary-color;
      background: $white;
    }

    &-title {
      color: $white;
    }

    &-text {
      color: $white;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    &:last-child {
      .timeline-block__item-index {
        span {
          &::before {
            background: $primary-color;
          }
        }
      }
    }
  }

  &__body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 0 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  &__title {
    @include adaptiv-font(48, 30);
    font-weight: 700;
    text-align: center;

    margin-bottom: 85px;

    @media screen and (max-width: 690px) {
      margin-bottom: 60px;
    }
  }

  &__list {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 33px;
      top: 0;
      width: 3px;
      height: 95%;

      @media screen and (max-width: 940px) {
        left: 19px;
      }

      @media screen and (max-width: 560px) {
        left: 16px;
      }
    }
  }

  &__item {
    display: flex;

    margin-left: 140px;

    position: relative;

    &:not(:last-child) {
      padding-bottom: 60px;
      margin-bottom: 60px;
    }

    &:last-child {
      .timeline-block__item-index {
        span {
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -300%;
            transform: translateX(-50%);
            width: 4px;
            height: 300%;

            @media screen and (max-width: 940px) {
              bottom: -600px;
              height: 600px;
            }
          }
        }
      }
    }

    @media screen and (max-width: 940px) {
      flex-direction: column;
      margin-left: 70px;
    }

    @media screen and (max-width: 560px) {
      margin-left: 60px;
    }
  }

  &__item-index {
    position: absolute;
    top: -20px;
    left: -140px;
    width: 70px;
    height: 70px;

    @include adaptiv-font(32, 18);
    font-weight: 600;

    border-radius: 100%;

    span {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
    }

    @media screen and (max-width: 940px) {
      width: 40px;
      height: 40px;
      left: -70px;
      top: -10px;
    }

    @media screen and (max-width: 560px) {
      width: 35px;
      height: 35px;
      left: -60px;
      top: -5px;
    }
  }

  &__item-info {
    margin-right: 50px;

    @media screen and (max-width: 940px) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__item-title {
    @include adaptiv-font(32, 22);
    font-weight: 600;

    margin-bottom: 30px;

    @media screen and (max-width: 690px) {
      margin-bottom: 20px;
    }
  }

  &__item-text {
    @include adaptiv-font(20, 17);
    line-height: 140%;
  }

  &__item-image {
    width: 100%;
    max-width: 370px;
    min-width: 370px;

    @media screen and (max-width: 940px) {
      min-width: 100%;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
}
</style>